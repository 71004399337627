<template>
  <div class="userEdit">
    <a-spin :spinning="isSpin">
      <a-form
        :model="formState"
        name="userEdit"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        autocomplete="off"
        @finish="onSubmit"
      >
        <a-form-item
          label="用户名"
          name="userName"
          :rules="[{ required: true, message: '请输入用户名' }]"
        >
          <a-input v-model:value="formState.userName"></a-input>
        </a-form-item>
        <a-form-item
          label="用户说明"
          name="description"
          :rules="[{ required: true, message: '请输入用户说明' }]"
        >
          <a-input v-model:value="formState.description"></a-input>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 3, span: 16 }">
          <a-button type="primary" html-type="submit">提交</a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import * as systemApi from "@/service/modules/system";
export default {
  data() {
    return {
      id: 0,
      formState: {
        userName: '',
        description: "",
      },
      isSpin: false,
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.$data.id = this.$route.query.id;
      this.getData();
    }
  },
  methods: {
    onSubmit(value) {
      //console.log(value);
      let postData = {
        id: this.$data.id,
        username: value.userName,
        description: value.description,
      };
      systemApi.userUpdate(postData).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          //   this.$closePage("user/userEdit", "user/user");
          this.$closePage(this.$router.currentRoute.value, { path: '/system/systemRoot/user', });
        }
      });
    },
    getData() {
      const self = this;
      systemApi.userDetail({ id: self.id }).then((res) => {
        //console.log(res);
        if (res.code == 200) {
          self.formState = {
            userName: res.data.username,
            description: res.data.description,
          };
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>